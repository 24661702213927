import { PageRendererProps } from 'gatsby';
import React from 'react';
import useRequireAuth from '../../../auth/useRequireAuth';
import Organizations from '../../../components/account/organizations';
import useAuth from '../../../State/Auth/useAuth';

const OrganizationsPage = ({ location }: PageRendererProps): JSX.Element | null => {
  useRequireAuth();
  const { admin } = useAuth();
  if (!admin) {
    return null;
  }

  return <Organizations location={location} />;
};

export default OrganizationsPage;
