import React, { useCallback, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  List,
  CircularProgress,
  Button,
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import SEO from '../../seo';
import useAdminOrganization from '../../../data/useAdminOrganization';
import AccountListItem from '../AccountListItem';
import InviteUserModal from './InviteUserModal';

interface OrganizationSingleProps {
  id: string;
}

const OrganizationSingle = ({ id }: OrganizationSingleProps) => {
  const [organization, requestStatus] = useAdminOrganization(id);
  const [inviteUserModal, setInviteUserModal] = useState(false);

  const onOpenInviteUserModal = useCallback(() => setInviteUserModal(true), []);
  const onCloseInviteUserModal = useCallback(() => setInviteUserModal(false), []);

  return (
    <>
      {organization && inviteUserModal && (
        <InviteUserModal organization={organization} onClose={onCloseInviteUserModal} />
      )}
      <SEO title="Organizations" />
      <Container maxWidth="md">
        <>
          {requestStatus.isLoading ? (
            <CircularProgress />
          ) : requestStatus.isError ? (
            <p>error</p>
          ) : requestStatus.isSuccess && organization ? (
            <>
              <Box mt={2} mb={2}>
                <Typography variant="h5" component="h2">
                  Organization: {organization.name}
                </Typography>
                <Box mt={2} mb={2}></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Card>
                  <List>
                    <AccountListItem
                      primary="Id"
                      secondary={organization.uid}
                      loading={false}
                      skeletonWidth="20px"
                      avatar={<VpnKeyIcon />}
                    />
                    <AccountListItem
                      primary="manuallySubscribedLicenses"
                      secondary={organization.manuallySubscribedLicenses || 0}
                      loading={false}
                      skeletonWidth="20px"
                      avatar={<VpnKeyIcon />}
                    />
                    <AccountListItem
                      primary="exclusiveLicenses"
                      secondary={organization.exclusiveLicenses || 0}
                      loading={false}
                      skeletonWidth="20px"
                      avatar={<VpnKeyIcon />}
                    />
                  </List>
                </Card>
              </Box>
              <Box mt={2} mb={2}>
                <Button onClick={onOpenInviteUserModal}>Invite user</Button>
              </Box>
            </>
          ) : null}
        </>
      </Container>
    </>
  );
};

export default OrganizationSingle;
