import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { RequestStateQuery } from '../@types/RequestQueryState';
import useAuth from '../State/Auth/useAuth';
import { ADMIN_ORGANIZATION } from './QueryKeys';

export interface AdminOrganisation {
  name: string;
  uid: string;
  exclusiveLicenses?: number;
  manuallySubscribedLicenses?: number;
}

const useAdminOrganization = (id: string): [AdminOrganisation | undefined, RequestStateQuery] => {
  const auth = useAuth();

  const get = useCallback(async () => {
    const token = await auth.getToken();

    const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/adminGetOrganization/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()) as AdminOrganisation;
  }, [id]);

  const { data, isLoading, isSuccess, isError } = useQuery<AdminOrganisation>(
    [ADMIN_ORGANIZATION, id, auth.user?.uid],
    get,
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return [data, { isLoading, isSuccess, isError }];
};

export default useAdminOrganization;
