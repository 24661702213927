import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Input,
  Typography,
} from '@material-ui/core';
import ButtonProgressContainer from '../form/button-progress-container';
import CircularProgressOverlay from '../form/circular-progress-overlay';
import useAdminInviteUserToOrganization from '../../../data/useAdminInviteUserToOrganization';
import DialogTitle from '../../dialog/dialog-title';
import { AdminOrganisation } from '../../../data/useAdminOrganization';

interface IniteUserModalProps {
  organization: AdminOrganisation;
  onClose: () => void;
}

const InviteUserModal = ({ organization, onClose }: IniteUserModalProps) => {
  const [inviteUser, requestStatus] = useAdminInviteUserToOrganization();

  const [email, setEmail] = useState('');

  const onInviteUser = useCallback(() => {
    if (email) {
      inviteUser({
        email,
        organization: organization.uid,
      });
    }
  }, [email, organization]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value),
    []
  );

  return (
    <>
      <Dialog open={!requestStatus.isSuccess} fullWidth>
        <DialogTitle onClose={onClose}>Invite user</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Invite to user to organization: <b>{organization.name}</b>
          </DialogContentText>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
            <Input fullWidth type="text" name="email" value={email} onChange={onChange} />
          </FormControl>
          {requestStatus.isError && (
            <Typography variant="body2" color="textSecondary" component="p">
              Error occured while sending the invitation, please try again.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonProgressContainer>
            <Button
              disabled={requestStatus.isLoading || !email}
              color="primary"
              onClick={onInviteUser}
            >
              Invite
            </Button>
            {requestStatus.isLoading && <CircularProgressOverlay size={30} />}
          </ButtonProgressContainer>
        </DialogActions>
      </Dialog>
      <Dialog open={requestStatus.isSuccess} fullWidth>
        <DialogTitle onClose={onClose}>Invitation sent</DialogTitle>
        <DialogContent>
          <DialogContentText>Invitation has been sent to: {email}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteUserModal;
