import React from 'react';
import { Container } from '@material-ui/core';

import SEO from '../../seo';
import { PageRendererProps } from 'gatsby';
import OrganizationsList from './organizationsList';
import OrganizationSingle from './organizationSingle';

interface OrganizationsProps {
  location: PageRendererProps['location'];
}

const Organizations = ({ location }: OrganizationsProps) => {
  const id =
    location.hash.length && location.hash !== '#' ? location.hash.replace('#', '') : undefined;

  return (
    <>
      <SEO title="Organizations" />
      <Container maxWidth="md">
        {id ? <OrganizationSingle id={id} /> : <OrganizationsList />}
      </Container>
    </>
  );
};

export default Organizations;
