import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { RequestStateQuery } from '../@types/RequestQueryState';
import useAuth from '../State/Auth/useAuth';

interface AdminInviteUserToOrganizationPayload {
  email: string;
  organization: string;
}

const useAdminInviteUserToOrganization = (): [
  (payload: AdminInviteUserToOrganizationPayload) => void,
  RequestStateQuery
] => {
  const auth = useAuth();

  const mutation = useMutation(async (payload: AdminInviteUserToOrganizationPayload) => {
    const token = await auth.getToken();

    return fetch(`${process.env.GATSBY_FUNCTIONS_URL}/adminInviteUserToOrganization`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
  });

  const { mutate, isLoading, isSuccess, isError } = mutation;
  return useMemo(
    () => [
      (payload: AdminInviteUserToOrganizationPayload) => mutate(payload),
      { isLoading, isSuccess, isError },
    ],
    [mutate, isLoading, isSuccess, isError]
  );
};

export default useAdminInviteUserToOrganization;
