import React, { useCallback } from 'react';
import {
  Paper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Container,
} from '@material-ui/core';

import useAdminOrganizations from '../../../data/useAdminOrganizations';
import SEO from '../../seo';

interface Column {
  id: 'uid' | 'name' | 'exclusiveLicenses' | 'manuallySubscribedLicenses';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'uid', label: 'Id', minWidth: 170 },
  { id: 'name', label: 'Name' },
  { id: 'exclusiveLicenses', label: 'Exclusive' },
  { id: 'manuallySubscribedLicenses', label: 'Subscribed' },
];

const OrganizationsList = () => {
  const [organizationResponse, request] = useAdminOrganizations();

  const onOrganizationClick = useCallback((uid: string) => {
    window.location.hash = uid;
  }, []);

  return (
    <>
      <SEO title="Organizations" />
      <Container maxWidth="md">
        <Box mt={2} mb={2}>
          <Typography variant="h5" component="h2">
            Organizations
          </Typography>
          <Box mt={2} mb={2}>
            {request.isLoading ? (
              <CircularProgress />
            ) : (
              <Paper>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organizationResponse?.organizations?.map((organization) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={organization.name}
                          onClick={(): void => onOrganizationClick(organization.uid)}
                        >
                          <TableCell>{organization.name}</TableCell>
                          <TableCell>{organization.name}</TableCell>
                          <TableCell>{organization.exclusiveLicenses || 0}</TableCell>
                          <TableCell>{organization.manuallySubscribedLicenses || 0}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default OrganizationsList;
